import React from "react";

import "./ToastMessage.css";

export default function ToastMessage({ message, type, icon }) {
  console.log("type:", type);
  console.log("message: ", message);
  return (
    <div className={`toast-container ${type}`}>
      <img src={icon} alt={type} />
      <span>{message}</span>
    </div>
  );
}
