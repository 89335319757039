import { useState } from "react";
import emailjs from "@emailjs/browser";

const useForm = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [valid, setValid] = useState({});

  const validate = (event, name, value) => {
    switch (name) {
      case "name":
        if (value.length < 1) {
          setErrors({
            ...errors,
            name: "ContactForm.Error.Name",
          });
        } else {
          setValid({
            ...valid,
            name: true,
          });
          const { name, ...newObj } = errors;
          setErrors(newObj);
        }
        break;
      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "ContactForm.Error.Email",
          });
        } else {
          setValid({
            ...valid,
            email: true,
          });
          const { email, ...newObj } = errors;
          setErrors(newObj);
        }
        break;
      case "phone":
        if (!new RegExp(/[0-9]/).test(value)) {
          setErrors({
            ...errors,
            phone: "ContactForm.Error.Phone",
          });
        } else {
          setValid({
            ...valid,
            phone: true,
          });
          const { phone, ...newObj } = errors;
          setErrors(newObj);
        }
        break;
      case "subject":
        if (value.length <= 1) {
          setErrors({
            ...errors,
            subject: "ContactForm.Error.Subject",
          });
        } else {
          setValid({
            ...valid,
            subject: true,
          });
          const { subject, ...newObj } = errors;
          setErrors(newObj);
        }
        break;
      case "body":
        if (value.length <= 1) {
          setErrors({
            ...errors,
            body: "ContactForm.Error.Body",
          });
        } else {
          setValid({
            ...valid,
            body: true,
          });
          const { body, ...newObj } = errors;
          setErrors(newObj);
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    event.persist();

    let name = event.target.name;
    let val = event.target.value;

    validate(event, name, val);
    setValues({
      ...values,
      [name]: val,
    });
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
      postEmailJs(values);
    } else {
      console.log("error");
    }
  };

  const postEmailJs = (formData) => {
    setIsLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (res) => {
          setIsSuccess(true);
          setIsLoading(false);
        },
        (err) => {
          setIsError(true);
        }
      );
  };

  return {
    values,
    errors,
    valid,
    isError,
    isSuccess,
    isLoading,
    setIsSuccess,
    handleChange,
    handleSubmit,
  };
};

export default useForm;
