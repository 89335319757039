import React, { createContext } from "react";

const LocaleContext = createContext();

const LocaleContextProvider = (props) => {
  return (
    <LocaleContext.Provider value={props.value}>
      {props.children}
    </LocaleContext.Provider>
  );
};

export { LocaleContext, LocaleContextProvider };
