import { React, useContext, useEffect } from "react";
import { WordpressContext } from "../../contexts/WordpressContext";
import { useTranslation } from "react-i18next";

import Hero from "../../components/Hero/Hero";
import Instagram from "../../components/Instagram/Instagram";
import "./HomePage.css";
import Laws from "../../components/Laws/Laws";

export default function HomePage() {
  const { homePageHero, laws, getHeroData, getAreasOfLaw, locale } =
    useContext(WordpressContext);
  const { t } = useTranslation();

  useEffect(() => {
    getHeroData(201);
    getAreasOfLaw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <>
      <Hero data={homePageHero} />
      <div className="page-container home-page-container">
        <div className="section">
          <h2 className="section-title laws">{t("HomePage.Laws.Header")}</h2>
          <div className="section-description">
            {t("HomePage.Laws.Description")}
          </div>
          <Laws laws={laws} />
        </div>
        <div className="section">
          <h2 className="section-title">{t("HomePage.Instagram.Header")}</h2>
          <div className="section-description">
            {t("HomePage.Instagram.Description")} @folketsadvokatbyra
          </div>
          <Instagram />
        </div>
      </div>
    </>
  );
}
