import { useState } from "react";
import axios from "axios";

const useInstagram = () => {
  const [instagramFeed, setInstagramFeed] = useState([]);
  const rootUrl = process.env.REACT_APP_IG_ROOT_URL;
  const userId = process.env.REACT_APP_IG_USER_ID;
  const token = process.env.REACT_APP_IG_TOKEN;

  const getInstagramFeed = async () => {
    try {
      let res = await axios.get(
        `${rootUrl}/${userId}/media?access_token=${token}&fields=media_url,media_type,caption,thumbnail_url,permalink,id`
      );
      setInstagramFeed(res.data.data.slice(0, 6));
    } catch (err) {
      // error handling code
      console.log(err);
    }
  };

  return {
    getInstagramFeed,
    instagramFeed,
  };
};

export default useInstagram;
