import React, { useContext, useEffect } from "react";
import Card from "../../components/Card/Card";
import Employer from "../../components/Employer/Employer";

import { WordpressContext } from "../../contexts/WordpressContext";
import { useTranslation } from "react-i18next";
import "./AboutPage.css";

export default function AboutPage() {
  const { aboutContentCards, employees, getEmployees, getAboutCards, locale } =
    useContext(WordpressContext);
  const { t } = useTranslation();

  useEffect(() => {
    getEmployees();
    getAboutCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);
  return (
    <div>
      <div className="about-section">
        <h1>{t("AboutPage.Hero.Title")}</h1>
        <p>{t("AboutPage.Hero.Description")}</p>
      </div>
      <div className="page-container">
        <div className="employees-section">
          <h2>{t("AboutPage.Employer.Header")}</h2>
          <div className="employees-container">
            {employees ? (
              employees.map((employer) => (
                <Employer data={employer.acf} key={employer.acf.name} />
              ))
            ) : (
              <div className="loading-container"></div>
            )}
          </div>
        </div>
        {aboutContentCards &&
          aboutContentCards.map((card, i) => (
            <Card
              key={card.id}
              type={card.acf.button.type}
              data={{
                title: card.acf.title,
                content: card.acf.description,
                image: card.acf.image.url,
                alt: card.acf.image.alt,
                button: card.acf.button,
              }}
              reverse={i % 2 === 0}
            />
          ))}
      </div>
    </div>
  );
}
