import React, { useState } from "react";

const ContactFormContext = React.createContext();

const ContactFormProvider = (props) => {
  const [showContactModal, setShowContactModal] = useState(false);

  const toggleContactForm = () => setShowContactModal(!showContactModal);

  const contextValues = {
    showContactModal,
    toggleContactForm,
  };

  return (
    <ContactFormContext.Provider value={contextValues}>
      {props.children}
    </ContactFormContext.Provider>
  );
};

export { ContactFormContext, ContactFormProvider };
