import React, { useContext, useCallback } from "react";
import { useState } from "react";
import { createContext } from "react";
import axios from "axios";
import { LocaleContext } from "./LocaleContext";

const WordpressContext = createContext();

const WordpressContextProvider = (props) => {
  const { locale } = useContext(LocaleContext);
  const [homePageHero, setHomePageHero] = useState(null);
  const [aboutHero, setAboutHero] = useState(null);
  const [lawHero, setLawHero] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [laws, setLaws] = useState([]);
  const [aboutContentCards, setAboutContentCards] = useState([]);
  const baseUrl = process.env.REACT_APP_WORDPRESS_API_URL;
  const jsonUrl = "wp-json/wp/v2";

  const getHeroData = useCallback(
    async (id) => {
      try {
        let res = await axios.get(
          `${baseUrl}/${
            locale === "en" ? "" : `${locale}/`
          }${jsonUrl}/views?include[]=${id}`
        );
        if (id === 201) {
          setHomePageHero(res.data[0]);
        }
        if (id === 202) {
          setAboutHero(res.data[0]);
        }
        if (id === 203) {
          setLawHero(res.data[0]);
        }
      } catch (err) {
        // error handling code
        console.log("get hero data error: ", err);
      }
    },
    [baseUrl, locale]
  );

  const getEmployees = async () => {
    try {
      let res = await axios.get(
        `${baseUrl}/${locale === "sv" ? "sv/" : ""}${jsonUrl}/employees`
      );
      setEmployees(res.data.sort((a, b) => a.acf.sort - b.acf.sort));
    } catch (err) {
      // error handling code
      console.log("get employees error: ", err);
    }
  };

  const getAreasOfLaw = async () => {
    try {
      let res = await axios.get(
        `${baseUrl}/${locale === "en" ? "" : `${locale}/`}${jsonUrl}/laws`
      );
      const mappedLaws = [];
      const migrationLawSubAreas = [];
      let migrationLawTitle = "";
      let migrationLawSort = "";
      const criminalLawSubAreas = [];
      let criminalLawTitle = "";
      let criminalLawSort = "";
      res.data.forEach((data) => {
        if (!data.acf.title) {
          return;
        }
        if (
          data.acf.title === "Brottmål" ||
          data.acf.title === "Criminal law"
        ) {
          criminalLawTitle = data.acf.title;
          criminalLawSort = data.acf.sort;
          criminalLawSubAreas.push(data.acf.subarea);
        } else if (
          data.acf.title === "Migration law" ||
          data.acf.title === "Migrationsrätt"
        ) {
          migrationLawTitle = data.acf.title;
          migrationLawSort = data.acf.sort;
          migrationLawSubAreas.push(data.acf.subarea);
        } else {
          mappedLaws.push({
            title: data.acf.title,
            subarea: [data.acf.subarea],
            sort: data.acf.sort,
          });
        }
      });
      const migrationLaw = {
        title: migrationLawTitle,
        subarea: migrationLawSubAreas.sort((a, b) =>
          a.sort.localeCompare(b.sort)
        ),
        sort: migrationLawSort,
      };
      const criminalLaw = {
        title: criminalLawTitle,
        subarea: criminalLawSubAreas.sort((a, b) =>
          a.sort.localeCompare(b.sort)
        ),
        sort: criminalLawSort,
      };
      mappedLaws.unshift(migrationLaw);
      mappedLaws.unshift(criminalLaw);
      setLaws(mappedLaws.sort((a, b) => a.sort.localeCompare(b.sort)));
    } catch (err) {
      console.log("areas of law error: ", err);
    }
  };

  const getAboutCards = async () => {
    try {
      let res = await axios.get(
        `${baseUrl}/${locale === "en" ? "" : `${locale}/`}${jsonUrl}/cards`
      );
      setAboutContentCards(
        res.data.slice(0, 6).sort((a, b) => a.acf.sort - b.acf.sort)
      );
    } catch (err) {
      console.log("get about cards error", err);
    }
  };

  const contextValues = {
    aboutContentCards,
    aboutHero,
    employees,
    homePageHero,
    lawHero,
    laws,
    locale,
    getAreasOfLaw,
    getEmployees,
    getHeroData,
    getAboutCards,
  };
  return (
    <WordpressContext.Provider value={contextValues}>
      {props.children}
    </WordpressContext.Provider>
  );
};

export { WordpressContext, WordpressContextProvider };
