import React from "react";

const ContactInput = ({
  inputName,
  title,
  errorType,
  validType,
  handleChange,
  successIcon,
  attentionIcon,
}) => {
  return (
    <div className="input-container">
      <label htmlFor="name">{title}</label>
      <input
        className={errorType ? "input-error" : ""}
        type="text"
        name={inputName}
        onChange={handleChange}
        required
      ></input>
      {!errorType && validType && (
        <img src={successIcon} className="attention-icon" alt="success icon" />
      )}
      {errorType && (
        <>
          <img
            src={attentionIcon}
            className="attention-icon"
            alt="attention icon"
          />
          <span className="input-error-message">* {errorType}</span>
        </>
      )}
    </div>
  );
};

export default ContactInput;
