import { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ContactFormContext } from "./contexts/ContactFormContext";
import { WordpressContextProvider } from "./contexts/WordpressContext";
import { LocaleContextProvider } from "./contexts/LocaleContext";
import { useTranslation } from "react-i18next";

// page & layout imports
import HomePage from "./pages/HomePage/HomePage";
import Header from "./components/Header/Header";
import AboutPage from "./pages/AboutPage/AboutPage";
import ContactModal from "./components/ContactModal/ContactModal";
import Footer from "./components/Footer/Footer";
import AreasOfLawPage from "./pages/AreasOfLawPage/AreasOfLawPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import AKPage from "./pages/AKPage/AKPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";

function App() {
  const appContext = useContext(ContactFormContext);
  const { showContactModal, toggleContactForm } = appContext;
  const { i18n, t } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);
  const showLandingPage = false;
  i18n.on("languageChanged", (lng) => setLocale(i18n.language));
  return (
    <LocaleContextProvider value={{ locale, setLocale }}>
      <WordpressContextProvider>
        {showLandingPage ? (
          <LandingPage />
        ) : (
          <>
            {" "}
            <div className="app">
              <div>
                <button
                  className="folkets-button cta floating-button"
                  onClick={toggleContactForm}
                >
                  {t("Common.Button.ContactUs")}
                </button>
                <Header />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/om-oss" element={<AboutPage />} />
                  <Route path="/rattsomraden" element={<AreasOfLawPage />} />
                  <Route
                    path="/advokatsamfundets-konsumenttvistnamnd"
                    element={<AKPage />}
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </div>
              <Footer />
              {showContactModal ? <ContactModal /> : ""}
            </div>
          </>
        )}
      </WordpressContextProvider>
    </LocaleContextProvider>
  );
}

export default App;
