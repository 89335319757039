import React from "react";
import { useEffect, useRef } from "react";
import useInstagram from "../../hooks/useInstagram";

import "./Instagram.css";
import chevronIcon from "../../assets/icons/chevron.svg";

export default function Instagram() {
  const { getInstagramFeed, instagramFeed } = useInstagram();
  const wrapperRef = useRef(null);

  useEffect(() => {
    getInstagramFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scroll = (scrollOffset) => {
    wrapperRef.current.scrollLeft += scrollOffset;
  };

  return (
    <div className="scroll-container">
      <button className="scroll-button" onClick={() => scroll(-300)}>
        <img className="chevron-icon left" src={chevronIcon} alt="left" />
      </button>
      <div className="ig-container" ref={wrapperRef}>
        {instagramFeed.length > 0 &&
          instagramFeed.map((post) => (
            <a
              className="ig-link"
              href={post.permalink}
              key={post.id}
              target="_blank"
              rel="noreferrer"
            >
              {post.media_type === "VIDEO" ? (
                <video className="ig-video">
                  <source src={`${post.media_url}#t=0.001`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <div className="ig-image-container">
                  {post && (
                    <img
                      src={post.media_url}
                      className="ig-image"
                      alt={post.caption}
                      width="300"
                      height="300"
                    />
                  )}
                </div>
              )}
            </a>
          ))}
      </div>
      <button className="scroll-button" onClick={() => scroll(300)}>
        <img className="chevron-icon right" src={chevronIcon} alt="rigth" />
      </button>
    </div>
  );
}
