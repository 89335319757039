import React, { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "../../contexts/LocaleContext";
import useClickOutside from "../../hooks/useClickOutside";

import "./Language.css";
import enIcon from "../../assets/icons/locales/en.svg";
import svIcon from "../../assets/icons/locales/sv.svg";
import { t } from "i18next";

export default function Language() {
  const ref = useRef();
  const { i18n } = useTranslation();
  const { locale } = useContext(LocaleContext);
  const [isOpen, setIsOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("Svenska");
  const [currentIcon, setCurrentIcon] = useState(svIcon);

  useEffect(() => {
    if (locale === "sv") {
      setCurrentLanguage(t("Language.Component.Swedish"));
      setCurrentIcon(svIcon);
    }
    if (locale === "en") {
      setCurrentLanguage(t("Language.Component.English"));
      setCurrentIcon(enIcon);
    }
  }, [locale]);

  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  const toggleLanguage = () => {
    setIsOpen(!isOpen);
  };
  const changeLocale = (language) => {
    if (locale !== language) {
      i18n.changeLanguage(language);
    }
    setIsOpen(false);
  };
  return (
    <div className="language-dropdown-container" ref={ref}>
      <button className="language-button" onClick={toggleLanguage}>
        <img src={currentIcon} alt={currentLanguage} />
        <span>{currentLanguage}</span>
      </button>
      {isOpen && (
        <div className="language-dropdown-content">
          <ul>
            <li onClick={() => changeLocale("en")}>
              <img src={enIcon} alt={t("Language.Component.English")} />
              English
            </li>
            <li onClick={() => changeLocale("sv")}>
              <img src={svIcon} alt={t("Language.Component.Swedish")} /> Svenska
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
