import useForm from "../../hooks/useForm";
import { useTranslation } from "react-i18next";

import attentionIcon from "../../assets/icons/attention.svg";
import successIcon from "../../assets/icons/success.svg";

import ContactInput from "./ContactInput";
import ContactTextarea from "./ContactTextarea";
import ToastMessage from "../ToastMessage/ToastMessage";

const ContactForm = ({ toggleContactForm }) => {
  const {
    handleChange,
    errors,
    handleSubmit,
    isError,
    isLoading,
    isSuccess,
    valid,
  } = useForm();
  const { t } = useTranslation();

  return (
    <>
      <form onSubmit={handleSubmit} method="post">
        <ContactInput
          title={t("ContactForm.Input.Name")}
          inputName={"name"}
          errorType={t(errors.name)}
          handleChange={handleChange}
          validType={valid.name}
          successIcon={successIcon}
          attentionIcon={attentionIcon}
        />
        <div className="flex-wrapper">
          <ContactInput
            title={t("ContactForm.Input.Email")}
            inputName={"email"}
            errorType={t(errors.email)}
            handleChange={handleChange}
            validType={valid.email}
            successIcon={successIcon}
            attentionIcon={attentionIcon}
          />
          <ContactInput
            title={t("ContactForm.Input.Phone")}
            inputName={"phone"}
            errorType={t(errors.phone)}
            handleChange={handleChange}
            validType={valid.phone}
            successIcon={successIcon}
            attentionIcon={attentionIcon}
          />
        </div>
        <ContactInput
          title={t("ContactForm.Input.Subject")}
          inputName={"subject"}
          errorType={t(errors.subject)}
          handleChange={handleChange}
          validType={valid.subject}
          successIcon={successIcon}
          attentionIcon={attentionIcon}
        />
        <ContactTextarea
          title={t("ContactForm.Input.Body")}
          textareaName={"body"}
          errorType={t(errors.body)}
          handleChange={handleChange}
          validType={valid.body}
          successIcon={successIcon}
          attentionIcon={attentionIcon}
        />
        <div className="contact-form-footer-container">
          {isError && (
            <ToastMessage
              message={t("ContactForm.Sending.Error")}
              type={"error"}
              icon={attentionIcon}
            />
          )}
          {isSuccess && (
            <ToastMessage
              message={t("ContactForm.Sending.Success")}
              type={"success"}
              icon={successIcon}
            />
          )}
          <div className="button-container">
            <button type="submit" className="folkets-button primary">
              {isLoading
                ? t("ContactForm.Button.Sending")
                : t("ContactForm.Button.Submit")}
            </button>
            <button
              className="folkets-button secondary"
              onClick={toggleContactForm}
            >
              {t("ContactForm.Button.Cancel")}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
