import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import "./Employer.css";
import emailIcon from "../../assets/icons/email.svg";
import phoneIcon from "../../assets/icons/phone.svg";
import enIcon from "../../assets/icons/locales/en.svg";
import svIcon from "../../assets/icons/locales/sv.svg";
import ruIcon from "../../assets/icons/locales/ru.svg";
import closeIcon from "../../assets/icons/close.svg";
import globeIcon from "../../assets/icons/globe.svg";

export default function Employer({ data }) {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const translatedLanguages = data.languages.map((language) => {
    if (language === "English") {
      return {
        name: t("Employer.Details.English"),
        icon: enIcon,
      };
    }
    if (language === "Russian") {
      return {
        name: t("Employer.Details.Russian"),
        icon: ruIcon,
      };
    }
    return {
      name: t("Employer.Details.Swedish"),
      icon: svIcon,
    };
  });
  const toggleReadMore = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  const handleClickOutside = (event) => {
    if (!wrapperRef.current.contains(event.target)) {
      toggleReadMore();
    }
  };

  return (
    <div className="employer-container">
      <div
        className={
          showMoreInfo
            ? "employer-content-container open"
            : "employer-content-container"
        }
      >
        <div className="employer-info-container">
          <div className="employer-image-container">
            {data.image && (
              <img
                className="employer-profile-image"
                src={data.image.sizes.large}
                alt={data.image.alt}
              />
            )}
          </div>
          <div className="employer-name-container">
            <h3 className="employer-header">{data.name}</h3>
            <span>{data.title}</span>
          </div>
          <div className="employer-details">
            <div className="employer-details-item-container">
              <img className="globe-icon" src={globeIcon} alt="language" />
              <span className="title">{t("Employer.Details.Language")}: </span>
              {translatedLanguages &&
                translatedLanguages.map((language) => (
                  <span
                    className="employer-language-icon-container"
                    key={language.name}
                  >
                    <img src={language.icon} alt={language.name} />
                  </span>
                ))}
            </div>
            <div className="employer-details-item-container">
              <img src={emailIcon} alt="email" />
              <span className="title">{t("Employer.Details.Email")}: </span>
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </div>
            {data.phone && (
              <div className="employer-details-item-container">
                <img src={phoneIcon} alt="phone" />
                <span className="title">{t("Employer.Details.Phone")}: </span>
                <a href={`tel:${data.phone}`}>{data.phone}</a>
              </div>
            )}
          </div>
        </div>
        {showMoreInfo && (
          <div
            className="employer-text-content-container"
            onClick={handleClickOutside}
          >
            <div className="employer-text-container" ref={wrapperRef}>
              <div className="employer-close-button-container">
                <button
                  className="employer-close-button"
                  onClick={toggleReadMore}
                >
                  <img src={closeIcon} alt="close" width="" height="" />
                </button>
              </div>
              <div className="employer-text-content-wrapper">
                <div className="employer-name-container">
                  <h3 className="employer-header">{data.name}</h3>
                  <span>{data.title}</span>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="read-more-button-container">
        <button
          className="folkets-button primary read-more-button"
          onClick={toggleReadMore}
        >
          {t("Common.Button.ReadMore")}
        </button>
      </div>
    </div>
  );
}
