import React from "react";

import "./Card.css";
import downloadIcon from "../../assets/icons/download.svg";
import externalIcon from "../../assets/icons/external-link.svg";

export default function Card({ data, type, reverse }) {
  return (
    <div className={reverse ? "card-container reverse" : "card-container"}>
      {data.image && (
        <div className="card-image-container">
          <img src={data.image} alt={data.alt} />
        </div>
      )}
      <div className="card-content-container">
        <h2>{data.title}</h2>
        <p>{data.content}</p>
        {type && data.button.title && (
          <a
            className="folkets-button primary card-download-button"
            href={data.button.url}
            download
          >
            {data.button.title}
            <img
              src={type === "download" ? downloadIcon : externalIcon}
              alt={type}
            />
          </a>
        )}
      </div>
    </div>
  );
}
