import React from "react";
import { Link } from "react-router-dom";
import "./Laws.css";

export default function Laws({ laws }) {
  return (
    <div className="laws-container">
      {laws &&
        laws.length > 0 &&
        laws.map((law, index) => (
          <Link
            to={`/rattsomraden/#${index}`}
            className="law-container"
            key={law.title}
          >
            {law.title.split(":").map((string, i) => (
              <div key={i}>
                {i === 0 && <h3>{string}</h3>}
                {i === 1 && <span>{string}</span>}
              </div>
            ))}
          </Link>
        ))}
    </div>
  );
}
