import React from "react";
import { createRoot } from "react-dom/client";
import "./i18n/config";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ContactFormProvider } from "./contexts/ContactFormContext";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ContactFormProvider>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </ContactFormProvider>
  </React.StrictMode>
);
