import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ContactFormContext } from "../../contexts/ContactFormContext";
import { useTranslation } from "react-i18next";

import "./Header.css";
import folketsLogo from "../../assets/logo/folkets-logo-color.svg";
import Language from "../Language/Language";

export default function Header() {
  const appContext = useContext(ContactFormContext);
  const { toggleContactForm } = appContext;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const navItems = [
    { path: "/", name: "Navbar.NavItem.Home" },
    { path: "/om-oss", name: "Navbar.NavItem.AboutUs" },
    { path: "/rattsomraden", name: "Navbar.NavItem.AreasOfLaw" },
  ];
  const onToggle = () => setIsMenuOpen(!isMenuOpen);
  return (
    <header className="header-container">
      <div className="header-content">
        <div className="logo">
          <Link to="/">
            <img src={folketsLogo} alt="Folkets Advokatbyrå logo" />
          </Link>
        </div>
        <div className="language-menu-container">
          <Language />
          <div
            className={isMenuOpen ? "menu-icon open" : "menu-icon"}
            onClick={onToggle}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <nav className={isMenuOpen ? "open" : ""}>
          <ul className="nav-list">
            {navItems.map((item) => (
              <li key={item.name}>
                <Link to={item.path} className="nav-link" onClick={onToggle}>
                  {t(item.name)}
                </Link>
              </li>
            ))}
            <li className="nav-list-item cta">
              <button
                className="folkets-button cta"
                onClick={toggleContactForm}
              >
                {t("Common.Button.ContactUs")}
              </button>
            </li>
            <li className="desktop">
              <Language />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
