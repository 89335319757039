import { useContext, useRef, useEffect } from "react";
import { ContactFormContext } from "../../contexts/ContactFormContext";
import { useTranslation } from "react-i18next";
import "./ContactModal.css";
import ContactForm from "./ContactForm";
import FocusTrap from "focus-trap-react";

import closeIcon from "../../assets/icons/close.svg";

const ContactModal = () => {
  const appContext = useContext(ContactFormContext);
  const { toggleContactForm } = appContext;
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);

  const handleEscapeKey = (event) => {
    if (event.code === "Escape") {
      toggleContactForm();
    }
  };

  const handleClickOutside = (event) => {
    if (!wrapperRef.current.contains(event.target)) {
      toggleContactForm();
    }
  };

  return (
    <div className="contact-modal-wrapper" onClick={handleClickOutside}>
      <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
        <div className="contact-modal-container" ref={wrapperRef}>
          <div className="contact-modal-close-button-container">
            <button
              className="contact-modal-close-button"
              onClick={toggleContactForm}
            >
              <img src={closeIcon} alt="close" width="20" height="20" />
            </button>
          </div>
          <div className="contact-modal-content-container">
            <div className="info-container">
              <h2>{t("ContactModal.Header")}</h2>
              <p>{t("ContactModal.Description")}</p>
            </div>
            <ContactForm toggleContactForm={toggleContactForm} />
          </div>
        </div>
      </FocusTrap>
    </div>
  );
};

export default ContactModal;
