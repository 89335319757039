import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import TextDropdown from "../../components/TextDropdown/TextDropdown";
import { WordpressContext } from "../../contexts/WordpressContext";

import "./AreasOfLawPage.css";

export default function AreasOfLawPage() {
  const { laws, getAreasOfLaw, locale } = useContext(WordpressContext);

  useEffect(() => {
    getAreasOfLaw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <div>
      <div className="law-hero-section">
        <h1>{t("AreasOfLawPage.Hero.Title")}</h1>
        <p>{t("AreasOfLawPage.Hero.Description")}</p>
      </div>
      <div className="law-topic-container page-container">
        {laws.length > 0 &&
          laws.map((law, index) => (
            <TextDropdown data={law} key={law.title} hash={index} />
          ))}
      </div>
    </div>
  );
}
