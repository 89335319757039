import React from "react";
import { useTranslation } from "react-i18next";

import "./Footer.css";
import facebookLogo from "../../assets/icons/facebook.svg";
import instagramLogo from "../../assets/icons/instagram.svg";
import { Link } from "react-router-dom";
export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="social-media-container">
          <div>{t("Footer.Text")}</div>
          <div className="icon-container">
            <a
              href="https://www.instagram.com/folketsadvokatbyra/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagramLogo} className="icon" alt="Instagram" />
            </a>
            <a
              href="https://www.facebook.com/folketsadvokatbyra"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={facebookLogo}
                className="icon facebook"
                alt="Facebook"
              />
            </a>
          </div>
        </div>
        <div className="footer-contact-details">
          <div>
            <span>Chapmansgatan 5, 112 36, Stockholm</span>
          </div>
          <div>
            <a href="mailto:info@folketsadvokatbyra.se">
              info@folketsadvokatbyra.se
            </a>
          </div>
          <div className="advokatsamfundet">
            <Link to="/advokatsamfundets-konsumenttvistnamnd">
              {t("AKPage.Hero.Title")}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
