import React from "react";
import { useTranslation } from "react-i18next";

import "./AKPage.css";

export default function AKPage() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="ak-page-header">
        <h1>{t("AKPage.Hero.Title")}</h1>
      </div>
      <div className="ak-page-container">
        <p>{t("AKPage.Advokatsamfundet.Description")}</p>
        <ul className="ak-page-contact">
          <li className="title">{t("AKPage.Contact.Address")}:</li>
          <li>
            Konsumenttvistnämnden Sveriges Advokatsamfund, Box 27321, 102 54
            Stockholm
          </li>
          <li className="title">{t("AKPage.Contact.Website")}:</li>
          <li>
            <a
              href="https://www.advokatsamfundet.se/konsumenttvistnamnden"
              target="_blank"
              rel="noreferrer"
            >
              www.advokatsamfundet.se/konsumenttvistnamnden
            </a>
          </li>
          <li className="title">{t("AKPage.Contact.Email")}:</li>
          <li>
            <a href="mailto:konsumenttvistnamnden@advokatsamfundet.se">
              konsumenttvistnamnden@advokatsamfundet.se
            </a>
          </li>
          <li className="title">{t("AKPage.Contact.Phone")}:</li>
          <li>
            <a href="tel:004684590300">+46 (0)8-459 03 00</a>
          </li>
          <li className="title">{t("AKPage.Contact.PhoneHours")}:</li>
          <li>09.00-12.00</li>
        </ul>
      </div>
    </div>
  );
}
