import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./NotFoundPage.css";

export default function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <div className="not-found-page-container">
      <div className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-description">{t("NotFoundPage.Description")}</p>
        <div className="not-found-button-container">
          <Link to="/" className="folkets-button primary not-found">
            {" "}
            {t("NotFoundPage.ButtonText")}
          </Link>
        </div>
      </div>
    </div>
  );
}
