import React from "react";

const ContactTextarea = ({
  title,
  textareaName,
  errorType,
  validType,
  handleChange,
  successIcon,
  attentionIcon,
}) => {
  return (
    <div className="textarea-container">
      <label htmlFor="body">{title}</label>
      <textarea
        className={`textarea ${errorType ? "input-error" : ""}`}
        id="body"
        name={textareaName}
        onChange={handleChange}
        required
      ></textarea>
      {!errorType && validType && (
        <img src={successIcon} className="attention-icon" alt="success icon" />
      )}
      {errorType && (
        <>
          <img
            src={attentionIcon}
            className="attention-icon"
            alt="attention icon"
          />
          <span className="input-error-message">* {errorType}</span>
        </>
      )}
    </div>
  );
};

export default ContactTextarea;
