import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ContactFormContext } from "../../contexts/ContactFormContext";

import "./Hero.css";

export default function Hero({ data }) {
  const contactFormContext = useContext(ContactFormContext);
  const { toggleContactForm } = contactFormContext;
  const { t } = useTranslation();
  return (
    <div className="hero-container">
      <div className="hero-content-container">
        <div className="hero-image-container">
          {data && (
            <img
              className="hero-image"
              src={data.acf.image.url}
              alt={data.acf.image.alt}
              width="960"
              height="640"
              loading="lazy"
            />
          )}
        </div>
        <div className="hero-text-container">
          <h1>{t("HomePage.Hero.Title")}</h1>
          <p>{t("HomePage.Hero.Description")}</p>
          <button
            className="folkets-button cta hero-contact"
            onClick={toggleContactForm}
          >
            {t("Common.Button.ContactUs")}
          </button>
        </div>
      </div>
    </div>
  );
}
