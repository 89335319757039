import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./TextDropdown.css";
import chevronIcon from "../../assets/icons/chevron.svg";

export default function TextDropdown({ data, hash }) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (location.hash === `#${hash.toString()}`) {
      setIsOpen(true);
      buttonRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }
    setIsOpen(false);
  }, [location.hash, hash]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="dropdown-container">
      <button
        className="dropdown-toggle-button"
        onClick={toggleDropdown}
        ref={buttonRef}
      >
        <h2>{data.title}</h2>
        <img
          className={
            isOpen ? "dropdown-toggle-icon toggle-open" : "dropdown-toggle-icon"
          }
          src={chevronIcon}
          alt="open"
        />
      </button>
      <div className={isOpen ? "dropdown-content open" : "dropdown-content"}>
        {data.subarea.length > 0 &&
          data.subarea.map((area) => (
            <div
              className="dropdown-subarea-container"
              key={area.title ? area.title : area.description}
            >
              {area.title && (
                <span className="dropdown-subarea-title">{area.title}</span>
              )}
              <p className="dropdown-subarea-description">{area.description}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
